'use strict';

export default function($stateProvider) {
  'ngInject';
  $stateProvider
  .state('companygenie', {
    url: '/companygenie',
    template: '<companygenie></companygenie>'
  });
}
